import axios from 'axios'
import store from '@/store'

export default async(params) => {
  let defaultLanguage = '';
  if (store.state.defaultLanguage !== null) {
    defaultLanguage = (store.state.defaultLanguage).toLowerCase();
  }

  try {
    const response = await axios({
      url: `${window.cipConfig.VUE_APP_API_ENDPOINT}app/user-consent/accepted-consent`,
      method: 'get',
      data: params,
      headers: {
        'X-localization': defaultLanguage,
      }
    });

    return response.data.data;
  } catch (error) {
    return null;
  }
}
