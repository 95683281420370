import store from '../../store'
import axios from 'axios'
import { isEmpty } from 'lodash';

function setNewImageVersion(key, oldPath, newPath) {
    fetch(newPath)
        .then(response => {
            const imagePath = response.ok
                ? `${newPath}?v=${new Date().getTime()}`
                : `${oldPath}?v=${new Date().getTime()}`;

            store.commit(key, imagePath)
        })
        .catch(error => {
            console.error('Error validating image URL:', error);

            store.commit(key, `${oldPath}?v=${new Date().getTime()}`)
        });
}

function preconnectLinks(link) {
    // Create a preconnect link element
    const preconnectLink = document.createElement('link');
    preconnectLink.rel = 'preconnect';
    preconnectLink.href = link;
    preconnectLink.crossorigin = 'anonymous';

    // Append the link element to the head section of HTML
    document.head.appendChild(preconnectLink);
}

export default async (langList, defautLang) => {
    let responseData = {}
    responseData.error = false;
    let defaultLanguageData = []
    let sliderData = [];
    let logo = '';
    let logoRedirectUrl = 'home';
    defautLang = "en";
    await axios.get(window.cipConfig.VUE_APP_API_ENDPOINT + "app/connect")
        .then((response) => {
            if (response.data.data) {

                let data = response.data.data;
                
                const assetPath =  window.cipConfig.VUE_APP_S3_IMAGE_PATH + data.tenantName;

                preconnectLinks(assetPath);

                 // Add default imagePath to store
                store.commit('setImagePath',assetPath);
                
                // Store slider in Local Storage
                if (data.sliders && Object.keys(data.sliders).length > 0) {
                    const sliderArray = Object.keys(data.sliders).map((key) => {
                        return data.sliders[key];
                    });
                
                    const firstSlider = sliderArray[0];
                    if (firstSlider && !firstSlider.imageLoaded) {
                        const image = new Image();
                        image.src = firstSlider.url;
                
                        firstSlider.imageLoaded = true;
                    }
                
                    store.commit('setSlider', JSON.stringify(sliderArray));
                } else {
                    const sliderData = [];
                    store.commit('setSlider', JSON.stringify(sliderData));
                }
                
                // Store language in Local Storage
                if (data.language) {
                    store.commit('setTenantDefaultLanguage', data.defaultLanguage)
                    let defaultLanguageDataChange = true

                    let listOfObjects = Object.entries(data.language);

                    // language code is always in capitalized form
                    listOfObjects.forEach(function (listOfLangauge) {
                        if (
                            store.state.defaultLanguageId
                            && !isEmpty(store.state.defaultLanguage)
                            && listOfLangauge[0] === store.state.defaultLanguageId.toString()
                            && listOfLangauge[1] === store.state.defaultLanguage.toUpperCase()
                        ) {
                            defaultLanguageDataChange = false;
                        }
                    });

                    // If options exist
                    if (listOfObjects) {

                        store.commit('setLanguageList', JSON.stringify(listOfObjects))
                        if (defaultLanguageDataChange == true) {
                            defaultLanguageData["selectedVal"] = (data.defaultLanguage) ? data.defaultLanguage : listOfObjects[0][1];
                            defaultLanguageData["selectedId"] = (data.defaultLanguageId) ? data.defaultLanguageId : listOfObjects[0][0];
                            store.commit('setDefaultLanguage', defaultLanguageData)
                        }

                    } else {
                        store.commit('setLanguageList', JSON.stringify(langList))
                        defaultLanguageData["selectedVal"] = (data.defaultLanguage) ? data.defaultLanguage : defautLang;
                        defaultLanguageData["selectedId"] = (data.defaultLanguageId) ? data.defaultLanguageId : "";
                        store.commit('setDefaultLanguage', defaultLanguageData)
                    }

                } else {
                    store.commit('setLanguageList', JSON.stringify(langList))
                    defaultLanguageData["selectedVal"] = defautLang;
                    defaultLanguageData["selectedId"] = "";
                    store.commit('setDefaultLanguage', defaultLanguageData)
                }

                //Set logo in local storage
                if (data.custom_logo) {
                    // we add the 'v' parameter to avoid retrieving the cached logo after an update
                    // this shouldn't cause any network issue as it is only run once at login
                    const path = `${store.state.imagePath}/assets/images/logo/logo`;
                    setNewImageVersion('setLogo', `${path}.png`, `${path}.webp`);
                    store.commit('setOldLogo', `${path}.png`);
                }

                // Set logo redirect url
                if (data.logo_redirect_url) {
                    logoRedirectUrl = data.logo_redirect_url;
                }
                store.commit('setLogoRedirectUrl', logoRedirectUrl);

                // Saml Settings
                if (data.saml_settings) {
                    store.commit('setSamlSettings', JSON.stringify(data.saml_settings));
                } else {
                    store.commit('setSamlSettings', JSON.stringify(false));
                }

                // Donation configs
                store.commit('setDonationConfig', data.auto_block_donation_config);

            } else {
                localStorage.removeItem('slider');
                localStorage.removeItem('listOfLanguage');
                localStorage.removeItem('defaultLanguage');
                localStorage.removeItem('defaultLanguageId');
                localStorage.removeItem('logo');
                localStorage.removeItem('logoRedirectUrl');
                let listOfObjects = {};
                store.commit('setLanguageList', JSON.stringify(listOfObjects))
                defaultLanguageData["selectedVal"] = defautLang;
                defaultLanguageData["selectedId"] = "";
                store.commit('setDefaultLanguage', defaultLanguageData)
                logo = '';
                store.commit('setLogo', logo)
                sliderData = [];
                store.commit('setSlider', JSON.stringify(sliderData))
                store.commit('setDonationConfig', null);
            }

            // Set no mission found message
            if (response.data.data.no_mission_custom_text) {
                store.commit('missionNotFound', response.data.data.no_mission_custom_text.translations);
            } else {
                store.commit('missionNotFound', '');
            }

            if (response.data.data.news_banner) {
                store.commit('newsBanner', response.data.data.news_banner);
            } else {
                store.commit('newsBanner', '');
            }
            if (response.data.data.news_banner_text && response.data.data.news_banner_text.translations) {
                store.commit('newsBannerText', response.data.data.news_banner_text.translations);
            } else {
                store.commit('newsBannerText', '');
            }

            if (response.data.data.story_banner) {
                store.commit('storyBanner', response.data.data.story_banner);
            } else {
                store.commit('storyBanner', '');
            }
            if (response.data.data.story_banner_text && response.data.data.story_banner_text.translations) {
                store.commit('storyBannerText', response.data.data.story_banner_text.translations);
            } else {
                store.commit('storyBannerText', '');
            }
            if (response.data.data.story_dashboard_text && response.data.data.story_dashboard_text.translations) {
                store.commit('storyDashboardText', response.data.data.story_dashboard_text.translations);
            } else {
                store.commit('storyDashboardText', '');
            }

            store.commit(
                'storiesSetup',
                response.data.data.stories_setup
                    ? response.data.data.stories_setup
                    : null
            );

            if (response.data.data.slide_interval) {
                store.commit('slideInterval', response.data.data.slide_interval);
            } else {
                store.commit('slideInterval', '');
            }
            if (response.data.data.slide_effect) {
                store.commit('slideEffect', response.data.data.slide_effect);
            } else {
                store.commit('slideEffect', '');
            }

            if (response.data.data.cookie_policy_text && response.data.data.cookie_policy_text.translations) {
                store.commit('cookiePolicyText', response.data.data.cookie_policy_text.translations);
            } else {
                store.commit('cookiePolicyText', '');
            }

            if (response.data.data.timesheet_from_year) {
                store.commit('timesheetFromYear', parseInt(response.data.data.timesheet_from_year));
            } else {
                store.commit('timesheetFromYear', '');
            }
            if (response.data.data.submit_new_mission_url) {
                store.commit('submitNewMissionUrl', response.data.data.submit_new_mission_url);
            } else {
                store.commit('submitNewMissionUrl', '');
            }
            // Site title
            if (response.data.data.site_title) {
                store.commit('siteTitle', JSON.stringify(response.data.data.site_title));
            } else {
                store.commit('siteTitle', JSON.stringify(false));
            }

            if (response.data.data.custom_login_text) {
                store.commit('customLoginText', JSON.stringify(response.data.data.custom_login_text));
            } else {
                store.commit('customLoginText', JSON.stringify(false));
            }

            if (response.data.data.mission_type_labels) {
                store.commit('missionTypeLabels', response.data.data.mission_type_labels);
            } else {
                store.commit('missionTypeLabels', '');
            }
            // set default theme
            store.commit('appTheme', '');

            if (response.data.data.volunteer_button_background_image) {
                const volunteerBg = response.data.data.volunteer_button_background_image;

                setNewImageVersion(
                    'volunteerButtonBackgroundImage',
                    volunteerBg,
                    `${store.state.imagePath}/home/volunteer/background-image.webp`
                );
            } else {
                store.commit('volunteerButtonBackgroundImage', '');
            }

            if (response.data.data.donation_button_background_image) {
                const donationBg = response.data.data.donation_button_background_image;

                setNewImageVersion(
                    'donationButtonBackgroundImage',
                    donationBg,
                    `${store.state.imagePath}/home/donation/background-image.webp`
                );
            } else {
                store.commit('donationButtonBackgroundImage', '');
            }

            if (response.data.data.date_picker_theme) {
                store.commit('datePickerTheme', JSON.stringify(response.data.data.date_picker_theme));
            } else {
                store.commit('datePickerTheme', JSON.stringify(false));
            }

            if (response.data.data.user_consent) {
                store.commit('userConsent', response.data.data.user_consent);
            } else {
                store.commit('userConsent', null);
            }

            store.commit(
                'matchingGiftSetupConfig',
                response.data.data.matching_gift_setup_config
                    ? response.data.data.matching_gift_setup_config
                    : null
            )

            const hasKey = ("swap_cip_mission_theme_and_dates" in response.data.data);

            if (hasKey) {
                store.commit('setSwapCipMissionThemeAndDates', response.data.data.swap_cip_mission_theme_and_dates);
            } else {
                store.commit('setSwapCipMissionThemeAndDates', 0);
            }

            store.commit(
                'teamVolunteer',
                response.data.data.team_volunteer
                    ? response.data.data.team_volunteer
                    : 0
            );

            store.commit(
                'allowTimesheetEntry',
                response.data.data.ALLOW_TIMESHEET_ENTRY
                    ? response.data.data.ALLOW_TIMESHEET_ENTRY
                    : null
            );
        })
        .catch(function () {

        });
    return responseData;
}
