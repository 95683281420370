import axios from 'axios'
import store from '../store'
import qs from 'qs';
import { isEmpty } from 'lodash';

let cancelToken = axios.CancelToken;
let cancel;

export default async (data) => {
    let responseData;
    let defaultLanguage = '';
    if (store.state.defaultLanguage !== null) {
        defaultLanguage = (store.state.defaultLanguage).toLowerCase();
    }

    cancel && cancel()

    const url = `${window.cipConfig.VUE_APP_API_ENDPOINT}app/filter-list`;
    const params = buildParams(data);

    await axios({
        url: url,
        method: 'get',
        headers: {
            'X-localization': defaultLanguage,
        },
        cancelToken: new cancelToken(function executor (event) {
            cancel = event
        }),
        params: params,
        paramsSerializer: params => qs.stringify(params)
    })
    .then((response) => {
        if (response.data.data) {
            responseData = response.data.data;
        } else {
            responseData = '';
        }
    })
    .catch(() => {});

    return responseData;
}

function buildParams(data) {
    const params = {};

    if (isNotEmpty(data.volunteeringTeamType)) {
        params.volunteering_team_type = data.volunteeringTeamType;
    }

    if (isNotEmpty(data.countryId)) {
        params.country_id = data.countryId;
    }

    if (isNotEmpty(data.stateId)) {
        params.state_id = data.stateId;
    }

    if (isNotEmpty(data.cityId)) {
        params.city_id = data.cityId;
    }

    if (isNotEmpty(data.themeId)) {
        params.theme_id = data.themeId;
    }

    if (isNotEmpty(data.organizationId)) {
        params.organization_id = data.organizationId;
    }

    if (isNotEmpty(data.typeId)) {
        params.type = data.typeId.toLowerCase();
    }

    if (isNotEmpty(data.typeId) && isNotEmpty(data.subTypeId)) {
        params.sub_type = data.subTypeId;
    }

    if (isNotEmpty(data.from) && isNotEmpty(data.to)) {
        params.from = data.from;
        params.to = data.to;
    }

    if (isNotEmpty(data.search)) {
        params.search = data.search;
    }

    if (isNotEmpty(data.exploreMissionType)) {
        params.explore_mission_type = data.exploreMissionType;
    }

    if (isNotEmpty(data.exploreMissionParams)) {
        params.explore_mission_params = data.exploreMissionParams;
    }

    if (data.advancedFilters && !isEmpty(data.advancedFilters)) {
        params.advanced_filters = data.advancedFilters;
    }

    return params;
}

function isNotEmpty(value = '') {
    return value !== '' && typeof value !== 'undefined';
}