import axios from 'axios'
import store from '../../store'
import { isEmpty } from 'lodash';

export default async(data) => {
    let responseData = {};
    let defaultLanguage = '';

    if (store.state.defaultLanguage !== null) {
        defaultLanguage = (store.state.defaultLanguage).toLowerCase();
    }

    let url = data && data.user_id
      ? `${window.cipConfig.VUE_APP_API_ENDPOINT}app/user-detail?user_id=${data.user_id}`
      : `${window.cipConfig.VUE_APP_API_ENDPOINT}app/user-detail`;

    if (isEmpty(data)) {
      document.body.classList.add("loader-enable");
    }

    await axios({
        url: url,
        method: 'GET',
        data: data,
        params: data,
        headers: {
            'X-localization': defaultLanguage,
        }
    })
      .then((response) => {
          responseData.error = false;
          responseData.message = response.data.message;
          responseData.data = response.data.data;
          document.body.classList.remove("loader-enable");
      })
      .catch(function(error) {
          if (error.response.data.errors[0].message) {
              responseData.error = true;
              responseData.message = error.response.data.errors[0].message;
          }
          document.body.classList.remove("loader-enable");
      });
    return responseData;
}