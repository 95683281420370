import axios from 'axios'
import store from '@/store'

export default async(params) => {
  let defaultLanguage = '';
  if (store.state.defaultLanguage !== null) {
    defaultLanguage = (store.state.defaultLanguage).toLowerCase();
  }

  return await axios({
    url: `${window.cipConfig.VUE_APP_API_ENDPOINT}app/teams/`,
    method: 'get',
    headers: {
      'X-localization': defaultLanguage,
    },
    params,
  })
  .then(({ data: { data }}) => data);
}
